<template>
    <AdminToast />
    <ConfirmDialog></ConfirmDialog>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='employee'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Prim</h3>

					<p class='text-lg'>

					</p>

				</Sidebar>

				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <project-employee-tab-menu :employee-id='employeeId' :project-employee-id='projectEmployeeId'></project-employee-tab-menu>

                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Prim Tarihi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Calendar id='startDate' dateFormat='dd.mm.yy' autocomplete='off'  :showIcon='true' :showButtonBar='true' v-model='bonus.bonusDate'></Calendar>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Prim Tutarı<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputNumber id='dayCount' autocomplete='off'  v-model='bonus.bonusPrice' showButtons></InputNumber>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Açıklama</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='comment' type='text' autocomplete='off' v-model='bonus.comment' />
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='save' label='Kaydet' icon='pi pi-save' @click='save' v-bind:disabled='isProcessing'></Button>
                        </div>
                    </div>

                    <div class='mt-5'>
                        <Toolbar class='mb-3 p-3'>
                            <template v-slot:start>
                                <div class='my-0'>
                                    <h5 class='mb-0'>
                                        <Calendar id='selectedMonth' view='month' dateFormat='MM - yy' :showIcon='true' :showButtonBar='true' v-model='selectedSalaryDate' @date-select='salaryDateChanged' class="mr-2"></Calendar>
                                    </h5>
                                </div>
                                <div>
                                    <Button id='save' label='Tümünü Göster' icon='pi pi-check' @click='showAllBonus' v-bind:disabled='isProcessing'></Button>
                                </div>
                            </template>
                        </Toolbar>

                        <DataTable :value='bonusList' class='p-datatable-gridlines' :rows='10' dataKey='id' :rowHover='true' :paginator='true' :loading='loadingIndicator' responsiveLayout='scroll'>

                            <template #empty>
                                Kayıtlı avans bulunamadı.
                            </template>

                            <template #loading>
                                Avanslar yükleniyor. Lütfen bekleyin.
                            </template>

                            <Column field='startDate' header='Prim Tarihi' style='width:10rem'>
                                <template #body='slotProps'>
                                    {{ formatDate(slotProps.data.bonusDate) }}
                                </template>
                            </Column>

                            <Column field='totalPrice' header='Prim Tutarı' style='width:10rem'>
                                <template #body='{data}'>
                                    {{ formatPrice(data.bonusPrice) }} &#8378;
                                </template>
                            </Column>
                            <Column field='comment' header='Açıklama'>
                            </Column>

                            <Column field='verified' header='Onay Durumu' dataType='boolean' bodyClass='text-center' style='width:6.5rem'>
                                <template #body='{data}'>
                                    <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isApproved, 'text-pink-500 pi-times-circle': !data.isApproved}"></i>
                                </template>
                            </Column>

                            <Column header='Sil' bodyClass='text-center' style='width:6.5rem' :exportable='false'>
                                <template #body='slotProps'>
                                    <Button icon='pi pi-trash' class='p-button-rounded p-button-primary' @click='deleteBonus(slotProps.data)' />
                                </template>
                            </Column>

                        </DataTable>
                    </div>
                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage, showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import moment from 'moment';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import {
    checkEmployeeSalaryCalculated, checkEmployeeSalaryChanged,
    checkEmployeeStillActive,
    getEmployee,
    getProjectEmployee,
} from '../common/commonFunctions';
import {
    checkActiveProjectIdIsValid,
    getActiveProjectId,
    getUserId,
    normalizeDate,
} from '../common/commonConstantFunctions';
import SmsService from '@/services/smsService';
import EmployeeBonusService from '@/services/employeeBonusService';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    _projectEmployeeService: null,
    _employeeBonusService: null,
    _smsService: null,

    created() {
        this._smsService = new SmsService();
        this._employeeService = new EmployeeService();
        this._employeeBonusService = new EmployeeBonusService();
        this._projectEmployeeService = new ProjectEmployeeService();

        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
        this.projectId = getActiveProjectId();

        this.selectedSalaryDate = moment().toDate();
        this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
        this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
    },

    async mounted() {
        this.employee = await getEmployee(this.employeeId);
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
        await this.getEmployeeBonusList();
    },
    data() {

        return {
            isProcessing: false,
            bonus: {
                projectEmployeeId: "",
                employeeId: "",
                projectId: "",
                bonusPrice: "",
                year: "",
                month: "",
                insertDate: "",
                isActive: "",
                isApproved: "",
                comment: "",
                managerUserId: "",
                bonusDate: "",
            },
            year: 1900,
            month: 1,
            selectedSalaryDate: null,
            loadingIndicator: false,
            employeeId: 0,
            projectEmployeeId: 0,
            bonusList: [],
            projectEmployee: {
                officialSalary: 0,
                unOfficialSalary: 0,
            },
            employee: {},
            visibleLeft: false,
        };
    },
    methods: {

        async salaryDateChanged() {
            this.loadingIndicator = true;
            this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
            this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
            await this.getEmployeeBonusList();
        },
        async showAllBonus() {
            this.loadingIndicator = true;
            await this.getEmployeeAllBonusList();
        },
        async save() {
            this.isProcessing = true;
            if (await this.validateForm()) {

                this.buildBonusCreateObject();

                const insertResponse = await this._employeeBonusService.insertEmployeeBonus(this.bonus);
                if (insertResponse.isSuccess) {
                    await this.getEmployeeBonusList();
                    await this._smsService.sendEmployeeNewBonusMessage(this.projectEmployeeId, insertResponse.data);
                    this.resetForm();

                    showSuccessMessage(this, 'Prim Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Prim Güncellemede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },
        async validateForm() {

            if(!checkEmployeeSalaryChanged(this, this.projectEmployee))
            {
                return false;
            }

            if (this.bonus.bonusDate == null || this.bonus.bonusDate == '') {
                showValidationMessage(this, 'Avans Tarihi Seçilmelidir');
                return false;
            }

            if (this.bonus.totalPrice == '') {
                showValidationMessage(this, 'Avans Tutarı Girilmelidir');
                return false;
            }

            if (await checkEmployeeSalaryCalculated(this, this.bonus.bonusDate, this.projectEmployeeId) == true) {
                return false;
            }

            if(await checkEmployeeStillActive(this, this.projectEmployeeId) == false)
            {
                return false;
            }

            return true;
        },


        async getEmployeeBonusList() {
            this.loadingIndicator = true;
            let startDate = normalizeDate(moment(this.selectedSalaryDate).startOf('month').toDate());
            let endDate = normalizeDate(moment(this.selectedSalaryDate).endOf('month').toDate());
            let bonusResponse = await this._employeeBonusService.getEmployeeBonuses(this.projectEmployeeId, startDate, endDate);
            if (bonusResponse.isSuccess) {
                this.bonusList = bonusResponse.data;
            }
            this.loadingIndicator = false;
        },

        async getEmployeeAllBonusList() {
            this.loadingIndicator = true;
            let bonusResponse = await this._employeeBonusService.getEmployeesAllBonuses(this.projectEmployeeId);
            if (bonusResponse.isSuccess) {
                this.bonusList = bonusResponse.data;
            }
            this.loadingIndicator = false;
        },

        async deleteBonus(item) {
            if (await checkEmployeeSalaryCalculated(this, item.bonusDate, this.projectEmployeeId) == true) {
                return false;
            }

            this.$confirm.require({
                message: 'Primi Silmek İstediğinize Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    if (item.isApproved) {
                        showErrorMessage(this, 'Bu Prim Onaylı. Bu Yüzden Silemezsiniz');
                        return;
                    }
                    let deleteResponse = await this._employeeBonusService.deleteEmployeeBonus(item.id);
                    if (deleteResponse.isSuccess) {
                        showSuccessMessage(this, 'Silme İşlemi Başarılı');
                        await this.getEmployeeBonusList();
                    } else {
                        showErrorMessage(this, 'Silme İşlemi Sırasında Hata Oluştu');
                    }
                },
            });
        },
        buildBonusCreateObject() {

            let bonusMonth = moment(this.bonus.bonusDate).toDate().getMonth() + 1;
            let bonusYear = moment(this.bonus.bonusDate).toDate().getFullYear();

            this.bonus.bonusDate = normalizeDate(this.bonus.bonusDate);
            this.bonus.managerUserId = getUserId();
            this.bonus.projectId = this.projectEmployee.projectId;
            this.bonus.employeeId = this.employeeId;
            this.bonus.isActive = true;
            this.bonus.isApproved = false;
            this.bonus.projectEmployeeId = this.projectEmployeeId;
            this.bonus.month = bonusMonth;
            this.bonus.year = bonusYear;
        },
        resetForm() {
            let bonusMonth = moment(this.bonus.bonusDate).toDate().getMonth() + 1;
            let bonusYear = moment(this.bonus.bonusDate).toDate().getFullYear();
            this.bonus.projectId = this.projectEmployee.projectId;
            this.bonus.employeeId = this.employeeId;
            this.bonus.bonusDate = null;
            this.bonus.comment = '';
            this.bonus.bonusPrice = 0;
            this.bonus.isActive = true;
            this.bonus.isApproved = false;
            this.bonus.month = bonusMonth;
            this.bonus.year = bonusYear;
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
    },
};

</script>


<style scoped>

</style>
